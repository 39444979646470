<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="경구약제">경구약제</b-form-radio>
              <b-form-radio value="인슐린">인슐린</b-form-radio>
              <b-form-radio value="경구약제+인슐린">경구약제+인슐린</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data === '인슐린' || q.answer[0].data === '' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-input type="text" v-model="q.answer[1].data" class="input-md" />
            <b-form-checkbox v-model="q.answer[1].data" value="모름" unchecked-value="">모름</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data === '경구약제' || q.answer[0].data === '' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[2].label }}</label>
            <b-form-input type="text" v-model="q.answer[2].data" class="input-md" />
            <b-form-checkbox v-model="q.answer[2].data" value="모름" unchecked-value="">모름</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <label>{{ q.answer[3].label }}</label>
            <b-form-radio-group v-model="q.answer[3].data">
              <b-form-radio value="잘한다">잘한다</b-form-radio>
              <b-form-radio value="잘 못한다">잘 못한다</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[3].data !== '잘 못한다' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[4].label }}</label>
            <b-form-input type="text" v-model="q.answer[4].data" class="input-lg"/>
          </b-col>
        </b-row>
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <label>{{ q.answer[5].label }}</label>
            <b-form-input type="text" v-model="q.answer[5].data" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q15',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '당뇨병 치료약제',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '당뇨병 치료약제',
        answer: [
          {
            data: '',
          },
          {
            label: '경구약제 이름',
            data: '',
          },
          {
            label: '인슐린 이름',
            data: '',
          },
          {
            label: '복용 투약',
            data: '',
          },
          {
            label: '이유',
            data: '',
          },
          {
            label: '한 달 기준 빠지는 횟수',
            data: '',
          },
        ],
      };
    }
    if (this.q.answer[0].data === '경구약제') this.q.answer[2].data = '';
    if (this.q.answer[0].data === '인슐린') this.q.answer[1].data = '';
    if (this.q.answer[3].data === '잘한다') this.q.answer[4].data = '';
  },
};
</script>